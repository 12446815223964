import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeaderTop from "../components/HeaderTop"
import HeaderColor from "../components/HeaderColor"
import Footer from "../components/Footer"
import CallToAction from "../components/CallToAction"
import options from "../../config/options"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const PageWrapper = styled.div`
  margin: 80px 0;
  h1 {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 38px;
    color: #323232;
    margin-bottom: 20px;
    text-align: center;
  }
  p {
    font-family: "Prompt", sans-serif;
    font-weight: 400;
    color: #848383;
  }
  u,
  strong {
    font-weight: 500;
    color: #323232;
  }
`

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <SEO
        title="นโยบายข้อมูลส่วนบุคคล | AEC brand | ปั๊มหอยโข่งโซล่าเซลล์ ปั๊มบาดาลโซล่าเซลล์"
        description="นโยบายข้อมูลส่วนบุคคล | AEC brand | ปั๊มหอยโข่งโซล่าเซลล์ ปั๊มบาดาลโซล่าเซลล์ ปั๊มโซล่าเซลล์ ระบบ Off Grid โซล่าเซลล์ สำหรับใช้กับไฟบ้าน และเพื่อการเกษตร"
      />
      <HeaderTop text={options.headerTop} />
      <HeaderColor />
      <PageWrapper>
        <div className="gb-container">
          <Row className="rowContent">
            <Col span={24}>
              <h1>นโยบายข้อมูลส่วนบุคคล</h1>
              <div className="contentWrapper">
                <p>
                  <strong>บริษัท เออีซีเอกซ์พอร์ต จำกัด (“บริษัท”)</strong>{" "}
                  ขอแจ้งให้ท่านที่ติดต่อมายังบริษัททราบว่า
                  บริษัทมีความจำเป็นต้องเก็บ รวบรวม
                  และใช้ข้อมูลส่วนบุคคลของท่านที่ ติดต่อมาใช้บริการของบริษัท
                  ภายใต้นโยบายข้อมูลส่วนบุคคลฉบับนี้
                  และเมื่อท่านติดต่อมายังบริษัท
                  ส่งต่อเปิดเผยข้อมูลส่วนบุคคลของท่าน ให้แก่บริษัทเพื่อ
                  ประโยชน์ในการติดต่อ ประสานงาน
                  และ/หรือการให้บริการจากบริษัทให้แก่ท่าน
                  บริษัทจะถือว่าท่านตกลงและยอมรับที่จะปฏิบัติตามนโยบายข้อมูลส่วนบุคคลฉบับนี้แล้ว
                </p>
                <br />
                <p>
                  <u>ข้อมูลส่วนบุคคลที่บริษัทประมวลผล</u>{" "}
                  บริษัทอาจได้รับข้อมูลส่วนบุคคลของท่าน ได้จากหลายช่องทาง ดังนี้
                </p>
                <p>(1) ได้รับโดยตรง จากการติดต่อสื่อสารระหว่างบริษัทและท่าน</p>
                <p>
                  (2) ได้รับทางอ้อมจากการอ้างอิงจากบุคคลอื่น
                  ซึ่งท่านอาจให้ความยินยอมให้บุคคลดังกล่าวเปิดเผยส่งต่อข้อมูลส่วนบุคคลของท่านให้แก่
                  บริษัท หรือ
                </p>
                <p>
                  (3) เก็บรวบรวมโดยอัตโนมัติด้วยระบบ
                  เมื่อท่านเข้ามาเยี่ยมชมเว็บไซต์และ/หรือใช้บริการต่าง ๆ
                  ของบริษัท
                </p>
                <p>
                  ข้อมูลส่วนบุคคลที่บริษัทมีความจําเป็นต้องเก็บ รวบรวม
                  ใช้และประมวลผลภายใต้นโยบายข้อมูลส่วนบุคคลฉบับนี้ ได้แก่
                </p>
                <p>
                  (1) ชื่อนามสกุล รวมถึงข้อมูลที่เกี่ยวเนื่องของท่าน
                  หรือของตัวแทนของท่าน (ในกรณีที่ท่านติดต่อมาในนามของนิติบุคคล)
                  ซึ่งอาจรวมถึงข้อมูลเอกสารการแสดงตน ของท่าน
                </p>
                <p>
                  (2) ข้อมูลการติดต่อ อาทิ เบอร์โทรศัพท์ อีเมล หรือข้อมูล Social
                  Media Account
                </p>
                <p>
                  (3) ข้อมูลส่วนบุคคลอื่น ๆ
                  ที่ท่านอาจให้แก่บริษัทระหว่างการติดต่อสื่อสาร เช่น
                  ข้อมูลเรื่องที่ต้องการติดต่อสอบถาม ข้อมูลความสนใจ
                  หรือข้อมูลประกอบอื่น ๆ ที่
                  อาจระบุตัวตนของท่านได้ซึ่งท่านให้แก่ บริษัท
                  โดยตรงผ่านช่องทางการติดต่อสื่อสารต่าง ๆ
                </p>
                <p>
                  (4) กรณีการติดต่อผ่าน Website อาจรวมถึงข้อมูลทางเทคนิคของท่าน
                  ได้แก่ IP Address, Cookies
                  รวมถึงข้อมูลพฤติกรรมการสืบค้นของท่าน
                </p>
                <br />
                <p>
                  <u>วัตถุประสงค์ในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล</u>{" "}
                  บริษัทจําเป็นต้องเก็บ รวบรวม
                  และใช้ข้อมูลส่วนบุคคลของท่านตามที่ระบุไว้ภายใต้นโยบายข้อมูลส่วนบุคคล
                  ฉบับนี้ เพื่อวัตถุประสงค์ต่าง ๆ ดังต่อไปนี้
                </p>
                <p>
                  (1) เพื่อการจัดการตอบรับการสื่อสารที่ท่านติดต่อมายังบริษัท
                  เช่น เพื่อการตอบคําถาม
                  การส่งข้อมูลที่เกี่ยวข้องตามที่ท่านร้องขอและต้องการ
                  การจัดการข้อ ร้องเรียน หรือการตอบรับความคิดเห็นต่าง ๆ
                  ที่ท่านให้แก่บริษัทโดยตรง ผ่านช่องทางการติดต่อสื่อสารต่าง ๆ
                  รวมถึงการประสานงานต่อเนื่องไปจนถึงการให้ บริการ
                  การจัดทําสัญญาการให้บริการ
                  และ/หรือการปฏิบัติสิทธิและหน้าที่ที่บริษัทและท่านอาจตกลงและจัดทําระหว่างกันต่อเนื่อง
                </p>
                <p>
                  (2)
                  เพื่อการปฏิบัติหน้าที่ตามกฎหมายที่บริษัทอาจอยู่ภายใต้เงื่อนไขที่ต้องปฏิบัติตาม
                  ซึ่งอาจรวมถึงแต่ไม่จํากัดเพียง
                  การปฏิบัติหน้าที่ในการจัดทําเอกสารบัญชี
                  และภาษีสําหรับการให้บริการใด ๆ ที่บริษัทอาจให้แก่ท่าน
                </p>
                <p>
                  (3) เพื่อการสร้างและปรับปรุงความสัมพันธ์ทางธุรกิจ
                  รวมถึงการปรับปรุงการให้บริการที่บริษัทจะดําเนินการเพื่อประโยชน์ของท่านให้ดีขึ้น
                  ซึ่งบริษัทอาจรวบรวม ใช้ข้้อมูลส่วนบุคคลของท่าน
                  เพื่อประโยชน์ในการควบคุม การรับประกันคุณภาพ
                  การวิเคราะห์การบริหารจัดการและแก้ไขปัญหาต่าง ๆ
                  ที่เกี่ยวข้องของบริษัท
                  รวมถึงแต่ไม่จํากัดเพียงการฝึกอบรมพนักงาน
                  หรือการวางแผนการให้ปรับปรุงการให้บริการในอนาคต
                </p>
                <p>
                  (4)
                  เพื่อประโยชน์ในการปกป้องและต่อสู้สิทธิอันชอบด้วยกฎหมายของบริษัท
                  ในกรณีที่อาจเกิดข้อพิพาท ระหว่างท่านและบริษัท ไม่ว่าในลักษณะใด
                  และ
                </p>
                <p>
                  (5) กรณีที่บริษัทอาจได้รับความยินยอม
                  ในการประมวลผลข้อมูลส่วนบุคคลด้วยจุดประสงค์เฉพาะเจาะจงจากท่าน
                  เช่น เพื่อการติดต่อประชาสัมพันธ์ทางการตลาด
                  และการประชาสัมพันธ์ข่าวสารต่าง ๆ
                  บริษัทจะประมวลผลข้อมูลตามวัตถุประสงค์ดังกล่าว
                </p>
                <br />
                <p>
                  <u>ระยะเวลาในการประมวลผลข้อมูลส่วนบุคคล</u>{" "}
                  เพื่อดําเนินการตามวัตถุประสงค์ที่ระบุไว้ข้างต้นทั้งหมด
                  บริษัทจําเป็นต้องเก็บรวบรวม และประมวลผลข้อมูลส่วนบุคคล
                  ของท่านเป็นระยะเวลา ดังนี้
                </p>
                <p>
                  (1) สําหรับการประมวลผลข้อมูลด้วยวัตถุประสงค์การให้บริการ
                  การจัดทําสัญญา และ/หรือการปฏิบัติสิทธิและหน้าที่ภายใต้สัญญา
                  บริษัทจําเป็นต้องประมวลผล ข้อมูลส่วนบุคคลของท่าน
                  ตราบเท่าที่บริษัทยังมีหน้าที่ให้บริการแก่ท่าน
                </p>
                <p>
                  (2) สําหรับการประมวลผลข้อมูล
                  ด้วยวัตถุประสงค์การปฏิบัติหน้าที่ตามกฎหมายของบริษัท
                  บริษัทจําเป็นต้องประมวลผลข้อมูลส่วนบุคคลของท่าน ไว้ตามระยะ
                  เวลาที่กฎหมายที่เกี่ยวข้องกําหนดไว้
                </p>
                <p>
                  (3)
                  สําหรับการประมวลผลข้อมูลส่วนบุคคลเพื่อการสร้างและปรับปรุงความสัมพันธ์ทางธุรกิจ
                  และ/หรือการปรับปรุงการให้บริการ บริษัทสงวนสิทธิในการเก็บ
                  รักษาข้อมูลส่วนบุคคลนั้นเท่าที่บริษัทอาจมีความจําเป็นทางธุรกิจ
                  โดยรับประกันไม่ให้กระทบสิทธิของท่านในฐานะเจ้าของข้อมูลเกินสมควร
                </p>
                <p>
                  (4) สําหรับการประมวลผลข้อมูลส่วนบุคคล
                  เพื่อการปกป้องและต่อสู้สิทธิอันชอบด้วยกฎหมายของบริษัท
                  บริษัทมีความจําเป็นเก็บรักษาข้อมูลส่วนบุคคลดังกล่าว
                  ตลอดอายุความที่เกี่ยวข้อง และ
                </p>
                <p>
                  (5) กรณีที่ท่านให้ความยินยอมแก่บริษัท
                  ในการประมวลผลข้อมูลด้วยวัตถุประสงค์เฉพาะ
                  บริษัทจะประมวลผลข้อมูลส่วนบุคคลของท่านจนกว่าท่านจะถอนความ
                  ยินยอม
                </p>
                <br />
                <p>
                  <u>การเปิดเผยข้อมูลส่วนบุคคล</u> โดยหลักการแล้ว
                  ข้อมูลส่วนบุคคลของท่านจะไม่ถูกเปิดเผย
                  ยกเว้นเป็นการเปิดเผยข้อมูลส่วนบุคคลที่บริษัทจําเป็นต้องดําเนินการ
                  ให้แก่ กลุ่มบุคคลดังนี้
                </p>
                <p>
                  (1) การเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่
                  ผู้ให้บริการภายนอกของบริษัท
                  ที่ให้การช่วยเหลือสนับสนุนบริษัทในการให้บริการแก่ท่าน
                  รวมถึงที่ปรึกษาด้านการ ดําเนินธุรกิจของบริษัท
                  โดยบริษัทจะส่งต่อและเปิดเผยข้อมูลส่วนบุคคลของท่านตามขอบเขตของวัตถุประสงค์ที่ระบุไว้และบนพื้นฐานเท่าที่จําเป็นเท่านั้น
                  หรือ
                </p>
                <p>
                  (2) กรณีที่บริษัทอาจอยู่ภายใต้บังคับคําสั่งหรือคําพิพากษา
                  ของหน่วยงานราชการ
                  บริษัทอาจมีความจําเป็นในการส่งต่อเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่
                  หน่วยงานดังกล่าว
                </p>
                <br />
                <p>
                  <u>
                    บริษัทรับประกันการจัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยที่เหมาะสม
                  </u>{" "}
                  เพื่อป้องกันการเข้าถึง การใช้การเปลี่ยนแปลง การแก้ไข
                  หรือการเปิดเผยข้อมูลส่วน บุคคลโดยปราศจากอํานาจหรือโดยมิชอบ
                  ทั้งนี้
                  บริษัทจะจัดให้มีการทบทวนมาตรการดังกล่าวเป็นระยะตามความเหมาะสม
                  และโดยสอดคล้องกับกฎหมายที่เกี่ยว ข้อง
                </p>
                <p>
                  <u>สิทธิของเจ้าของข้อมูล</u> บริษัทเคารพสิทธิของท่าน
                  ในฐานะเจ้าของข้อมูลภายใต้กฎหมายที่เกี่ยวข้อง
                  โดยท่านสามารถติดต่อบริษัท เพื่อขอใช้สิทธิของท่าน ซึ่งได้แก่
                  (1) สิทธิในการถอนความยินยอม (2) สิทธิในการขอเข้าถึง (3)
                  สิทธิในการขอรับสําเนาข้อมูลส่วนบุคคลของท่าน (4)
                  สิทธิในการขอปรับปรุงข้อมูลให้ถูกต้อง (5) สิทธิในการ
                  ขอคัดค้านการประมวลผลข้อมูลส่วนบุคคล (6)
                  สิทธิในการขอโอนถ่ายข้อมูลที่เก็บบันทึกในรูปแบบอิเล็กทรอนิกส์ที่สามารถถ่ายโอนได้ง่าย
                  รวมถึงการโอนถ่ายไปให้แก่ ผู้ควบคุมข้อมูลส่วนบุคคลอื่น (7)
                  สิทธิขอให้ลบหรือทําลายข้อมูลของท่านเมื่อหมดความจําเป็น (8)
                  สิทธใินการขอให้ระงับการประมวลผลข้อมูลส่วนบุคคลชั่วคราว และ (8)
                  สิทธิในการร้องเรียนได้
                </p>
                <br />
                <p>
                  <u>ข้อมูลติดต่อบริษัท</u>
                </p>
                <p>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</p>
                <p>
                  ที่อยู่: 165/2 ถนนรามอินทรา แขวงอนุสาวรีย์ เขตบางเขน
                  กรุงเทพมหานคร 10220
                </p>
                <p>
                  อีเมล หรือเบอร์โทรศัพท์: SolarBear.Thailand@gmail.com /
                  0844441494
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
      <Footer />
      <CallToAction />
    </Layout>
  )
}

export default PrivacyPolicyPage
